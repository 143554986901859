import _keyBy from "lodash/keyBy";
import _mapValues from "lodash/mapValues";
import common from '@mixins/common';
import { mapGetters } from 'vuex';
import * as assetsApi from '@api/assets';
import moment from 'moment';
import vueEsign from 'vue-esign';
import imageCompression from 'browser-image-compression';
const productPropertyList = [{
  label: "易耗品",
  value: 1
}, {
  label: "低值品",
  value: 2
}, {
  label: "固定资产",
  value: 3
}];
const productPropertyMap = _mapValues(_keyBy(productPropertyList, i => i.value), j => j.label);
export default {
  name: 'AssetsInDetail2',
  mixins: [common],
  components: {
    vueEsign
  },
  computed: {
    ...mapGetters('user', ['userInfo', 'projectInfo']),
    totalPrice() {
      let sum = 0;
      this.detailList.forEach(product => {
        try {
          let price = Math.round(parseFloat(product.inPrice || 0) * 100);
          sum += price * (product.inNumTotal || 0);
        } catch (e) {}
      });
      return sum / 100;
    }
  },
  data() {
    return {
      from: '',
      sourceId: '',
      sourceTitle: '',
      sourceType: '',
      inApplicant: '',
      spaceFullCode: '',
      spaceName: '',
      remark: '',
      stockUser: '',
      detailList: [],
      stockUserSignUrl: '',
      stockInImgList: [],
      outKeeper: '',
      remark0: '',
      productPropertyMap,
      inSpaceListShow: false,
      inSpaceList: [],
      gdListShow: false,
      gdList: []
    };
  },
  created() {
    this.sourceId = this.$route.query.sourceId;
    this.sourceTitle = this.$route.query.sourceTitle;
    this.sourceType = this.$route.query.sourceType;
    this.stockUser = this.userInfo.nickName;
    this.projectId = this.projectInfo.dcProjectId;
    this.outTime = moment().format('YYYY-MM-DD HH:mm:ss');
    this.getData();
  },
  methods: {
    getData() {
      assetsApi.getStockInDetailInit({
        sourceId: this.sourceId,
        sourceType: this.sourceType
      }).then(res => {
        let item = res.data.data;
        this.outKeeper = item.outUser;
        this.remark0 = item.applyDesc;
        // let sum = 0;
        for (let product of item.detailList) {
          // let price = Math.round(parseFloat(product.inPrice) * 100);
          // sum += price * product.inNumTotal;
          product.outStockId = item.outSpaceFullCode;
        }
        this.detailList = item.detailList;
        // this.totalPrice = sum / 100;
      });
    },

    onInSpaceListShow() {
      let {
        dcProjectId
      } = this.projectInfo;
      assetsApi.getSpaceListByRole(dcProjectId).then(res => {
        if (res.data.data.result) {
          this.inSpaceList = res.data.data.result;
          this.inSpaceListShow = true;
          this.$nextTick(() => {
            let value = this.spaceFullCode;
            let selectIndex = this.inSpaceList.findIndex(i => i.spaceFullCode === value);
            this.$refs.inSpaceList.setIndexes([selectIndex]);
          });
        } else {
          this.inSpaceListShow = true;
        }
      });
    },
    onInSpaceListSelect(item) {
      this.spaceFullCode = item.spaceFullCode;
      this.spaceName = item.spaceFullName;
      this.inSpaceListShow = false;
    },
    onClearSign() {
      this.$refs.esign.reset();
      this.stockUserSignUrl = '';
    },
    afterFileRead(item) {
      let up = this.$toast.loading({
        forbidClick: true,
        duration: 0
      });
      imageCompression(item.file, {
        maxSizeMB: 0.2,
        initialQuality: 0.7
      }).then(file => {
        assetsApi.fileUpload(item).then(res => {
          this.$toast.clear();
          const cb = res.data;
          if (cb.code === 200) {
            item.fileType = 1;
            item.url = cb.data.url;
            item.fileUrl = cb.data.url;
            item.status = 'success';
            item.message = '上传成功';
          } else {
            item.status = 'failed';
            item.message = '上传失败';
          }
        }).catch(e => {
          this.$toast.clear();
          item.status = 'failed';
          item.message = '上传失败';
        });
      }).catch(() => {
        this.$toast.clear();
        item.status = 'failed';
        item.message = '上传失败';
      });
    },
    async onConfirm() {
      if (!this.spaceFullCode) {
        this.$toast('请选择调入仓库');
        return;
      }
      if (!this.stockUserSignUrl) {
        let base64Str;
        try {
          base64Str = await this.$refs.esign.generate();
          if (base64Str) {
            base64Str = base64Str.substring(23);
          }
        } catch (e) {
          this.$toast('请签名');
          return;
        }
        let res = await assetsApi.fileUploadBase64(base64Str);
        this.stockUserSignUrl = res.data.data.src;
      }
      if (this.stockInImgList.filter(i => i.status == 'success').length == 0) {
        this.$toast('请上传图片');
        return;
      }
      this.$dialog.confirm({
        message: '请确认是否提交？'
      }).then(() => {
        assetsApi.inStock({
          sourceType: this.sourceType,
          sourceId: this.sourceId,
          sourceTitle: this.sourceTitle,
          spaceFullCode: this.spaceFullCode,
          spaceName: this.spaceName,
          projectId: this.projectId,
          stockUserSignUrl: this.stockUserSignUrl,
          stockInImgList: this.stockInImgList.map(i => {
            return {
              fileUrl: i.fileUrl
            };
          }),
          ourUser: this.outKeeper,
          remark: this.remark,
          detailList: this.detailList
        }).then(res => {
          if (res.data.data && res.data.data.guDingStockList && res.data.data.guDingStockList.length) {
            this.gdList = res.data.data.guDingStockList;
            this.gdListShow = true;
          } else {
            this.$router.replace({
              name: 'AssetsInSuccess',
              query: {
                sourceType: this.sourceType
              }
            });
          }
        });
      }).catch(() => {
        // on cancel
      });
    },
    onGdListConfirm() {
      this.gdListShow = false;
      this.$router.replace({
        name: 'AssetsInSuccess',
        query: {
          sourceType: this.sourceType
        }
      });
    }
  }
};