var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.routeMetaTitle
          },
          on: {
            "go-back": _vm.goBack
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "page"
        }, [_c('div', {
          staticClass: "list"
        }, _vm._l(_vm.detailList, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "item"
          }, [_c('div', {
            staticClass: "title"
          }, [_c('span', [_vm._v("申请明细：第" + _vm._s(index + 1) + "条")])]), _c('van-cell-group', {
            staticClass: "cell-group"
          }, [_c('van-cell', {
            attrs: {
              "title": "资产属性",
              "value": _vm.productPropertyMap[item.productProperty] || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "资产名称",
              "value": item.productName || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "主规格",
              "value": item.standard || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "资产编号",
              "value": item.productNo || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "调出仓库",
              "value": item.outStockName || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "调入价格/元",
              "value": item.inPrice || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "单位",
              "value": item.unit || '-'
            }
          }), item.productProperty === 3 ? _c('van-cell', {
            attrs: {
              "title": "调入数量"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function () {
                return [_c('van-stepper', {
                  attrs: {
                    "integer": "",
                    "min": 0,
                    "max": 1
                  },
                  model: {
                    value: item.inNumTotal,
                    callback: function ($$v) {
                      _vm.$set(item, "inNumTotal", $$v);
                    },
                    expression: "item.inNumTotal"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }) : _c('van-cell', {
            attrs: {
              "title": "调入数量"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function () {
                return [_c('van-stepper', {
                  attrs: {
                    "integer": "",
                    "min": 0
                  },
                  model: {
                    value: item.inNumTotal,
                    callback: function ($$v) {
                      _vm.$set(item, "inNumTotal", $$v);
                    },
                    expression: "item.inNumTotal"
                  }
                })];
              },
              proxy: true
            }], null, true)
          })], 1)], 1);
        }), 0), _c('van-cell-group', {
          staticClass: "cell-group"
        }, [_c('van-cell', {
          attrs: {
            "title": "调入仓库",
            "required": "",
            "is-link": ""
          },
          on: {
            "click": _vm.onInSpaceListShow
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "pickerText",
                class: {
                  disabled: _vm.spaceName === ''
                }
              }, [_vm._v(_vm._s(_vm.spaceName || '请选择') + " ")])];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          attrs: {
            "title": "调出管理员",
            "value": _vm.outKeeper || '-'
          }
        }), _c('van-cell', {
          attrs: {
            "title": "入库人",
            "value": _vm.stockUser || '-'
          }
        }), _c('van-cell', {
          attrs: {
            "title": "调拨总金额/元",
            "value": _vm.totalPrice
          }
        })], 1), _c('van-cell-group', {
          staticClass: "cell-group form",
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('div', {
                staticClass: "cell-group-title"
              }, [_c('span', [_vm._v("其他信息")])])];
            },
            proxy: true
          }])
        }, [_c('div', {
          staticClass: "remark0"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v("说明")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(_vm._s(_vm.remark0 || '-'))])]), _c('van-field', {
          staticClass: "vtextarea",
          attrs: {
            "type": "textarea",
            "label": "备注",
            "rows": "3",
            "autosize": "",
            "input-align": "left",
            "placeholder": "请输入"
          },
          model: {
            value: _vm.remark,
            callback: function ($$v) {
              _vm.remark = $$v;
            },
            expression: "remark"
          }
        }), _c('van-cell', {
          attrs: {
            "title": "入库人签字",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "signPanel"
              }, [_c('vue-esign', {
                ref: "esign",
                attrs: {
                  "bgColor": "#F5F5F5",
                  "isClearBgColor": false,
                  "format": "image/jpeg"
                }
              }), _c('div', {
                staticClass: "clear",
                on: {
                  "click": function ($event) {
                    $event.stopPropagation();
                    return _vm.onClearSign.apply(null, arguments);
                  }
                }
              }, [_vm._v("清空画布")])], 1)];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          attrs: {
            "title": "上传照片",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "uploadPanel"
              }, [_c('van-uploader', {
                attrs: {
                  "after-read": _vm.afterFileRead
                },
                model: {
                  value: _vm.stockInImgList,
                  callback: function ($$v) {
                    _vm.stockInImgList = $$v;
                  },
                  expression: "stockInImgList"
                }
              })], 1)];
            },
            proxy: true
          }])
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "footer"
        }, [_c('van-button', {
          attrs: {
            "block": "",
            "type": "primary",
            "size": "large"
          },
          on: {
            "click": _vm.onConfirm
          }
        }, [_vm._v("提交")])], 1)];
      },
      proxy: true
    }])
  }), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.inSpaceListShow,
      callback: function ($$v) {
        _vm.inSpaceListShow = $$v;
      },
      expression: "inSpaceListShow"
    }
  }, [_c('van-picker', {
    ref: "inSpaceList",
    attrs: {
      "show-toolbar": "",
      "value-key": "spaceName",
      "columns": _vm.inSpaceList
    },
    on: {
      "confirm": _vm.onInSpaceListSelect,
      "cancel": function ($event) {
        _vm.inSpaceListShow = false;
      }
    }
  })], 1), _c('van-dialog', {
    attrs: {
      "title": "注意",
      "confirm-button-text": "我已知晓"
    },
    on: {
      "confirm": _vm.onGdListConfirm
    },
    model: {
      value: _vm.gdListShow,
      callback: function ($$v) {
        _vm.gdListShow = $$v;
      },
      expression: "gdListShow"
    }
  }, [_c('div', {
    staticClass: "gdList"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("此次盘点资产中，有以下固定资产被赋予资产编号")]), _c('table', [_c('thead', [_c('tr', [_c('td', [_vm._v("资产名称")]), _c('td', [_vm._v("主规格")]), _c('td', [_vm._v("资产编号")])])]), _c('tbody', _vm._l(_vm.gdList, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(item.productName || '-'))]), _c('td', [_vm._v(_vm._s(item.standard || '-'))]), _c('td', [_vm._v(_vm._s(item.productNo || '-'))])]);
  }), 0)])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };